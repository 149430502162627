import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Geometric Design Highway Safety`}</h1>
    <h2>{`Geometric design and highway safety books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19MPKVSm2ZICB1BjyLM5895wKC8iYlO02"
      }}>{`Download: A policy on geometric design of highways and streets 6th ed.`}</a></p>
    <h2>{`Geometric design and highway safety lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Design-controls-and-criteria-1.pdf"
      }}>{`Design controls and criteria 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Design-controls-and-criteria-2.pdf"
      }}>{`Design controls and criteria 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Elements-of-highway-design.pdf"
      }}>{`Elements of highway design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/geometric-design-and-highway-safety-introduction.pdf"
      }}>{`geometric design and highway safety introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Highway-surveys-and-locations.pdf"
      }}>{`Highway surveys and locations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/Vertical-alignment.pdf"
      }}>{`Vertical alignment`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      